import { globalHistory } from "@reach/router";
import nprogress from "nprogress";
import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";

nprogress.configure({
  showSpinner: false,
  minimum: 0.3,
});

const initHubspot = () => {
  const chat = window.$hubspot_chat;
  if (chat) {
    if (!chat.status().loaded) {
      chat.load({ widgetOpen: false });
    }
  } else {
    setTimeout(() => initHubspot(), 3000);
  }
};

export const onClientEntry = () => {
};

export const onInitialClientRender = () => {
  window.addEventListener("load", () => {
    window.addEventListener(
      "scroll",
      () => {
        initHubspot();
      },
      { once: true },
    );
  });

  /**
   * This is a workaround for a bug in Gatsby
   *
   * See https://github.com/gatsbyjs/gatsby/issues/8357 for more details
   */
  globalHistory._onTransitionComplete();
};

export const onRouteUpdate = () => {
  nprogress.done();
};
